import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import i18next from '../i18next';
import axios from 'axios';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      formError: undefined,
      loading: false,
    };
  }


  handleChange = e => this.setState({ [e.target.name]: e.target.value });


  handleSubmit = event => {
    event.preventDefault();
    const { loading } = this.state;
    if (!loading){
      this.setState({ loading: true });
      axios
          .post(
              "https://getform.io/f/afa422fe-8608-4df5-9c3d-b4df39abcb52",
              this.state,
              { headers: { Accept: "application/json" } }
          )
          .then((res) => {
            this.setState({
              formError: true,
              name: '',
              email: '',
              phone: '',
              message: '',
              loading: false
            });
          })
          .catch((error) => {
            this.setState({
              formError: false,
              loading: false
            });
          });
    }
  };

  hiddenForm = () => {
    this.setState({
      showForm: !(this.state.showForm)
    });
  };

  render() {
    const { showForm, formError, loading } = this.state;

    let formMessage;

    if (formError) {
      formMessage = i18next.t('app_site.contact.success');
    } else if (formError === false ) {
      formMessage = i18next.t('app_site.contact.fail');
    }
    return (
      <div className="section__page contact">
      <div className="contact__side contact__left">
        <div className="contact__form">
          <h3>{i18next.t('app_site.contact.title')}</h3>
          <GoogleReCaptchaProvider reCaptchaKey="6Lfwg70eAAAAAFMMlZ8DHEcXW0ohn9yRp7sOOFRH">
            <form className={showForm ? "active" : ""} onSubmit={this.handleSubmit}>
              <div style={{display: 'none'}} className={`alert ${formError === false ? "alert-danger" : "" } ${formError === true ? "alert-success" : "" }`} role="alert">
                <p>{formMessage}</p>
              </div>
              <i className="fas fa-times" onClick={this.hiddenForm}></i>
              <div className="contact__form-input">
                <label>{i18next.t('app_site.contact.label.name')}</label>
                <input type="text" name="name" id="name" value={this.state.name} required onChange={this.handleChange} />
              </div>
              <div className="contact__form-input">
                <label>{i18next.t('app_site.contact.label.e_mail')}</label>
                <input type="email" name="email" id="email" value={this.state.email} required onChange={this.handleChange}/>
              </div>
              <div className="contact__form-input">
                <label>{i18next.t('app_site.contact.label.phone')}</label>
                <input type="phone" name="phone" id="phone" value={this.state.phone} onChange={this.handleChange}/>
              </div>
              <div className="contact__form-input">
                <label>{i18next.t('app_site.contact.label.message')}</label>
                <textarea name="message" id="message" value={this.state.message} onChange={this.handleChange}/>
              </div>
              <button type="submit" className={loading ? "disabled" : ""}>{i18next.t('app_site.contact.button')}</button>
              <GoogleReCaptcha onVerify={token => this.setState({'g-recaptcha-response': token})} />
            </form>

          </GoogleReCaptchaProvider>
        </div>
        <Tabs className="contact__address">
          <TabPanel>
            <div className="contact__info">
              <h4>HQ</h4>
              <div className="contact__info-text">
                <p><strong>T</strong>	&nbsp;0850 640 0850	&nbsp;	&nbsp;</p><p><strong>E</strong>	&nbsp;sales@lagina.app</p><p>Yahyakemal Mah. Şair Sok. No. 1 Kat 1 Kağıthane - İSTANBUL</p>
              </div>
            </div>
            <div className="contact__map">
              <iframe title="HQ" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.3036364016743!2d28.98357531579663!3d41.08421197929313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6ed3b0c2323%3A0x748aa52dea5dc38f!2zWWFoeWFrZW1hbCwgxZ5haXIgU2suIE5vOjEgRDpLYXQgMSwgMzQ0MTAgS8OixJ_EsXRoYW5lL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1565249314278!5m2!1str!2str" width="100%" height="100%" frameBorder="0" border="0" />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="contact__info">
              <h4>Chicago</h4>
              <div className="contact__info-text">
                <p><strong>T</strong>	&nbsp;0850 640 0850	&nbsp; 	&nbsp;</p><p><strong>E</strong>	&nbsp;sales@lagina.app</p><p>TTC 2800 Lively Blvd Eld Groove Village IL 60007-6733 - USA</p>
              </div>
            </div>
            <div className="contact__map">
              <iframe title="Chicago" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.6705490410923!2d-87.9733555841806!3d41.98588267921377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb17f3479435b%3A0x5f77113441446ecb!2sTTC+Chicago!5e0!3m2!1str!2str!4v1565250182060!5m2!1str!2str" width="100%" height="100%" frameBorder="0" border="0" ></iframe>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="contact__info">
              <h4>Teknokent</h4>
              <div className="contact__info-text">
                <p><strong>T</strong>	&nbsp;0850 640 0850	&nbsp; 	&nbsp;</p><p><strong>E</strong> &nbsp;sales@lagina.app</p><p>Barış Mah. Koşu Yolu Cad. No:26 İç Kapı No:1 Gebze - Kocaeli</p>
              </div>
            </div>
            <div className="contact__map">
              <iframe title="Teknokent" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.4666803225778!2d29.459683315703792!3d40.79573754042476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb20e2d370f573%3A0x4f6f368fd09d0ca7!2sGebze+Teknokent!5e0!3m2!1str!2str!4v1565248516018!5m2!1str!2str" width="100%" height="100%" frameBorder="0" border="0" />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="contact__info">
              <h4>Montreal</h4>
              <div className="contact__info-text">
                <p><strong>T</strong>	&nbsp;0850 640 0850	&nbsp; 	&nbsp;</p><p><strong>E</strong> &nbsp;sales@lagina.app</p><p>4-79B BOUL. Brunswick Dollard-Des-Ormeaux Quebec H9B 2J5 - Canada</p>
              </div>
            </div>
            <div className="contact__map">
              <iframe title="Canada" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.62945171404!2d-73.81283738497345!3d45.477267879101085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93c8b675de707%3A0xe946dbf30fa6e034!2s479%20Boulevard%20Brunswick%2C%20Dollard-des-Ormeaux%2C%20QC%20H9B%202J5%2C%20Kanada!5e0!3m2!1str!2str!4v1568300172600!5m2!1str!2str" width="100%" height="100%" frameBorder="0" border="0" />
            </div>
          </TabPanel>

          <TabList className="contact__tab">
            <Tab className="contact__tab-dot"></Tab>
            <Tab className="contact__tab-dot"></Tab>
            <Tab className="contact__tab-dot"></Tab>
            <Tab className="contact__tab-dot"></Tab>
          </TabList>
        </Tabs>
        <div className="contact__show-form" onClick={this.hiddenForm}>
          Bize Yazın
        </div>
      </div>
    </div>
    );
  }
}

export default Contact;
