import React, { Component } from "react";
import { Link } from 'react-router-dom';
import sozlesme from '../assets/documents/sozlesme.svg';
import teslimat from '../assets/documents/teslimat.svg';
import kontrat from '../assets/documents/kontrat.svg';
import makbuz from '../assets/documents/makbuz.svg';
import izin from '../assets/documents/izin.svg';

import agreement from '../assets/documents/agreement.svg';
import delivery from '../assets/documents/delivery.svg';
import contract from '../assets/documents/contract.svg';
import receipt from '../assets/documents/receipt.svg';
import warrant from '../assets/documents/warrant.svg';
import i18next from '../i18next';


class SignPage1 extends Component {

  render() {
    console.log(i18next.language)
    return (
      <div className="about-sign__sign-page about-sp1">
        <a className="about-sign__turn-back" href="/"><i className="fal fa-chevron-left"></i>{i18next.t('app_page.link')}</a>
        <div className="about-sign__side about-sp1__left">
          <div className="about-sign__inner-content about-sp1__inner-content">
            <h2>{i18next.t('app_page.page1.title')}</h2>
            <p>{i18next.t('app_page.page1.text')}</p>
          </div>
        </div>
        <div className="about-sign__side about-sp1__right">
          <div className="about-sp1__document">
            <Link to={{pathname: "/imza-2", state: { document: i18next.t('app_page.documents.type1') }}}><img src={i18next.language === 'tr' ? sozlesme : agreement} alt="" /></Link>
          </div>
          <div className="about-sp1__document">
            <Link to={{pathname: "/imza-2", state: { document: i18next.t('app_page.documents.type2') }}}><img src={i18next.language === 'tr' ? teslimat : delivery} alt="" /></Link>
          </div>
          <div className="about-sp1__document">
            <Link to={{pathname: "/imza-2", state: { document: i18next.t('app_page.documents.type3') }}}><img src={i18next.language === 'tr' ? kontrat : contract} alt="" /></Link>
          </div>
          <div className="about-sp1__document">
            <Link to={{pathname: "/imza-2", state: { document: i18next.t('app_page.documents.type4') }}}><img src={i18next.language === 'tr' ? makbuz : receipt} alt="" /></Link>
          </div>
          <div className="about-sp1__document">
            <Link to={{pathname: "/imza-2", state: { document: i18next.t('app_page.documents.type5') }}}><img src={i18next.language === 'tr' ? izin : warrant} alt="" /></Link>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage1;