import React, { Component } from 'react'
import { Route, BrowserRouter } from "react-router-dom";
import { Twitter } from 'react-social-sharing'
import SiteApp from './SiteApp';
import SignPage1 from './Sign/SignPage1'
import SignPage2 from './Sign/SignPage2'
import SignPage2b from './Sign/SignPage2b'
import SignPage2a from './Sign/SignPage2a'
import SignPage2a1 from './Sign/SignPage2a1'
import SignPage2a2 from './Sign/SignPage2a2'
import SignPage2a3 from './Sign/SignPage2a3'
import SignPage2a4 from './Sign/SignPage2a4'
import i18next from './i18next';
import Cookies from 'universal-cookie';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'tr',
      loading: false,
    }
  }

  componentWillMount() {
    const cookies = new Cookies();
    
    this.setState({
      language: cookies.get('language')
    })
  }
  
  onLanguageChanged = (language) => {
    const cookies = new Cookies();
    
    cookies.set('language', language, { path: '/' });


    this.setState({
      loading: true
    })

    setTimeout(() => {
      this.setState({
        language,
        loading: false
      })
    }, 1);
  }

  render() {
    i18next.changeLanguage(this.state.language)
    const { loading } = this.state;
    let button;

    if (loading) {
      return (
        <div className="loader"></div>
      );
    }
    
    else {
      if(i18next.language === "tr") {
        button = <button type="button" onClick={() => this.onLanguageChanged('en')}>EN</button>
      }

      else if(i18next.language === "en") {
        button = <button type="button" onClick={() => this.onLanguageChanged('tr')}>TR</button>
      }
      return (
        <div className="App">
          <BrowserRouter>
            <Route exact path="/" component={SiteApp}/>
            <div className="about-sign">
              <Route exact path='/imza-1' component={SignPage1}/>
              <Route path='/imza-2' component={SignPage2}/>
              <Route path='/imza-2b' component={SignPage2b}/>
              <Route path='/imza-2a' component={SignPage2a}/>
              <Route path='/imza-2a1' component={SignPage2a1}/>
              <Route path='/imza-2a2' component={SignPage2a2}/>
              <Route path='/imza-2a3' component={SignPage2a3}/>
              <Route path='/imza-2a4' component={SignPage2a4}/>
            </div>
            <div id="home-nav">
              {button}
              <Twitter link={window.location.href}/>
            </div>
          </BrowserRouter>
        </div>
      )
    }
  }
}
export default App