import React, { Component } from "react";
import { Link } from 'react-router-dom';
import SignUp from '../components/SignUp';
import i18next from '../i18next';

 
class SignPage2a extends Component {

  constructor(props) {
    super(props);
    this.state = {
      document: "undefined" ? i18next.t('app_page.documents.type1') : this.props.location.state.document,  //contains the document type(name/title) passes through router link
      dataURL: "",
      shrink: true
    };
  }

  setDataUrl = (dataURL) => {
    this.setState({
      dataURL
    })
  }

  render() {
 
    return (
      <div className="about-sign__sign-page about-sp2a">
        <div className="about-sp2a__tablet-bg"></div>
        <div className="about-sign__inner-content about-sp2a__inner-content">
          <h2>{i18next.t('app_page.page2a.title')}</h2>
          <p>{i18next.t('app_page.page2a.big_text')}</p>
          <SignUp getDataUrl={this.setDataUrl}/>
          <h5>{i18next.t('app_page.page2a.small_text')}</h5>
          <div className="about-sp2a__choices  about-sign__choices">
          <Link to={{pathname: "/imza-2", state: { document: this.state.document }}} className="about-sign__nav--other">{i18next.t('app_page.page2a.button1')}</Link>
          <Link to="/imza-2a1" className="about-sign__nav">{i18next.t('app_page.page2a.button2')}</Link>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2a;