import React, { Component } from "react";
import { Link } from 'react-router-dom';
import i18next from '../i18next';

 
class SignPage2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      document: document === "undefined" ? i18next.t('app_page.documents.type1') : this.props.location.state.document,
    };
  }

  render() {
    return (
      <div className="about-sign__sign-page about-sp2">
        <Link className="about-sign__turn-back" to='/imza-1'><i className="fal fa-chevron-left"></i>{i18next.t('app_page.link')}</Link>
        <div className="about-sign__side about-sp2__left">
          <div className="about-sign__inner-content about-sp2__inner-content">
            <h2>{i18next.t('app_page.page2.title')}</h2>
            <p>{i18next.t('app_page.page2.text')}</p>
            <div className="about-sp2__choices  about-sign__choices">
              <Link to={{pathname: "/imza-2b", state: { document: this.state.document }}} className="about-sign__nav--other">{i18next.t('app_page.page2.button1')}</Link>
              <Link to={{pathname: "/imza-2a", state: { document: this.state.document }}} className="about-sign__nav">{i18next.t('app_page.page2.button2')}</Link>
            </div>
          </div>
        </div>
        <div className="about-sign__side about-sp2__right">
          <div className="about-sp2__document">
            <h3>{this.state.document}</h3>
            <div className="about-sp2__document-sign">
              <h6>{i18next.t('app_page.page2.sign_text')}</h6>
              <div className="about-sp2__document-sign-place"></div>
            </div>
          </div>
          <div className="about-sp2__blocks">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2;