import React, { Component } from "react";
import { Link } from 'react-router-dom';
import i18next from '../i18next';

 
class SignPage2a1 extends Component {


  render() {
    return (
      <div className="about-sign__sign-page about-sp2a1">
        <div className="about-sign__inner-content about-sp2a1__inner-content">
          <h2>{i18next.t('app_page.page2a1.title')}</h2>
          <p>{i18next.t('app_page.page2a1.text')}</p>
          <div className="about-sp2a1__advantages">
            <div className="about-sp2a1__adv">
              <i className="fas fa-clock"></i>
              <p>{i18next.t('app_page.page2a1.icon_text1')}</p>
            </div>
            <div className="about-sp2a1__adv">
              <i className="fas fa-tree"></i>
              <p>{i18next.t('app_page.page2a1.icon_text2')}</p>
            </div>
            <div className="about-sp2a1__adv">
              <i className="fas fa-tint"></i>
              <p>{i18next.t('app_page.page2a1.icon_text3')}</p>
            </div>
          </div>
          <div className="about-sp2a1__choices  about-sign__choices">
          <a href="/#AVANTAJLAR" className="about-sign__nav--other">{i18next.t('app_page.page2a1.button1')}</a>
          <Link to="/imza-2a2" className="about-sign__nav">{i18next.t('app_page.page2a1.button2')}</Link>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2a1;