import React, { Component } from "react";
import { Stage, Layer, Line } from "react-konva";


class Drawable {
  constructor(startx, starty) {
    this.startx = startx;
    this.starty = starty;
  }
}

class FreePathDrawable extends Drawable {
  constructor(startx, starty) {
    super(startx, starty);
    this.points = [startx, starty];
  }
  registerMovement(x, y) {
    this.points = [...this.points, x, y];
  }
  render() {
    return <Line points={this.points} lineCap="round" lineJoin="round" key={this.points} fill="black" strokeWidth={5} stroke="black" />;
  }
}

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawables: [],
      newDrawable: [],
      newDrawableType: "FreePathDrawable", 
      width: 0,
    };
  }

  getNewDrawableBasedOnType = (x, y, type) => {
    const drawableClasses = {
      FreePathDrawable
    };
    return new drawableClasses[type](x, y);
  };

  handleMouseDown = e => {
    const { newDrawable } = this.state;
    if (newDrawable.length === 0) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const newDrawable = this.getNewDrawableBasedOnType(
        x,
        y,
        this.state.newDrawableType
      );
      this.setState({
        newDrawable: [newDrawable]
      });
    }
  };

  handleMouseUp = e => {
    const { newDrawable, drawables } = this.state;
    if (newDrawable.length === 1) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const drawableToAdd = newDrawable[0];
      drawableToAdd.registerMovement(x, y);
      drawables.push(drawableToAdd);
      this.setState({
        newDrawable: [],
        drawables
      });

      const dataURL = this.layerRef.canvas.toDataURL({ //çizileni benzerlik için Data Url'sine çeviriyor
        mimeType: "image/jpeg",
        quality: 0,
        pixelRatio: 2
      });
      this.props.getDataUrl(dataURL)
    }
  };

  handleMouseMove = e => {
    const { newDrawable } = this.state;
    if (newDrawable.length === 1) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const updatedNewDrawable = newDrawable[0];
      updatedNewDrawable.registerMovement(x, y);
      this.setState({
        newDrawable: [updatedNewDrawable]
      });
    }
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const drawables = [...this.state.drawables, ...this.state.newDrawable];
    const WIDTH = this.state.width;

    return (
      <Stage
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={this.handleMouseMove}
        onTouchStart={this.handleMouseDown}
        onTouchEnd={this.handleMouseUp}
        onTouchMove={this.handleMouseMove}
        width={(WIDTH <= 768) ? (WIDTH * 0.85) : (WIDTH <= 1366 ? 660 : 720)}
        height={WIDTH <= 480 ? 150 : ((WIDTH <= 1366) ? 180 : (WIDTH <= 1440 ? 220 : 270))}
      >
        <Layer ref={ref => { this.layerRef = ref; }}>
          {drawables.map(drawable => {
            return drawable.render();
          })}
        </Layer>
      </Stage>
    );
  }
}


export default SignUp;