import React from "react"
import ReactFullpage from '@fullpage/react-fullpage'
import { Link} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Advantages from './components/Advantages';
import Contact from './components/Contact';
import Turkcell from './assets/references/icon-turkcell.svg';
import Havaist from './assets/references/icon-havaist.svg';
import i18next from './i18next';


const anchors = ["LAGINA", "NEDİR", "ÖZELLİKLER", "AVANTAJLAR", "REFERANSLAR", "HAKKIMIZDA", "İLETİŞİM"]

const SiteApp = () => (
  <ReactFullpage
    navigation
    navigationTooltips={anchors}
    showActiveTooltip
    scrollOverflowReset={true}
    fitToSectionDelay={500}
    anchors={anchors}
    render={({ fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <div className="section__page home">
              <div className="home__side home__left">
                <div className="home__content">
                  <div className="home__icon">
                    LAGINA<br/>SIGNATURE

                  </div>
                  <h1>{i18next.t('app_site.home')}</h1>
                  <button onClick={() => fullpageApi.moveTo("İLETİŞİM")}>
                    {i18next.t("app_site.about_home.demo_title")}
                  </button>
                </div>
              </div>
              <div className="home__side home__right">
                <div className="home__image"></div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section__page about-home">
              <div className="about-home__side about-home__left">
                <h2>{i18next.t('app_site.about_home.small_title')}</h2>
              </div>
              <div className="about-home__side about-home__right">
                <div className="about-home__right-content">
                  <h1>{i18next.t('app_site.about_home.big_title')}</h1>
                  <Link className="about-home__nav about-sign__nav" to='/imza-1'>{i18next.t('app_site.about_home.button')}</Link>
                </div>
              </div>
              <div className="about-home__big-rectangle"></div>
              <div className="about-home__icon about-home__circle-icon"></div>
              <div className="about-home__icon about-home__triangle-icon"></div>
              <div className="about-home__icon about-home__rectangle-icon"></div>
              <div className="about-home__icon about-home__burger-icon"></div>
              <div className="about-home__icon about-home__shadow-rectangle"></div>
              <div className="about-home__icon about-home__sign"></div>
              <div className="about-home__icon about-home__disk"></div>
            </div>
          </div>
          <div className="section">
            <div className="section__page properties">
              <div className="properties__side properties__left">
                <h3>{i18next.t('app_site.properties.title')}</h3>
                <div className="properties__content">
                  <div className="properties__content-left">
                    <p>{i18next.t('app_site.properties.text1')}</p>
                    <p>{i18next.t('app_site.properties.text2')}</p>
                    <p>{i18next.t('app_site.properties.text3')}</p>
                    <p>{i18next.t('app_site.properties.text4')}</p>
                    <p>{i18next.t('app_site.properties.text5')}</p>
                  </div>
                  <div className="properties__content-right">
                    <p>{i18next.t('app_site.properties.text6')}</p>
                    <p>{i18next.t('app_site.properties.text7')}</p>
                    <p>{i18next.t('app_site.properties.text8')}</p>
                    <p>{i18next.t('app_site.properties.text9')}</p>
                    <p>{i18next.t('app_site.properties.text10')}</p>
                    <p>{i18next.t('app_site.properties.text11')}</p>
                  </div>
                </div>
              </div>
              <div className="properties__side properties__right">
                <div className="properties__image-blocks">
                  <div className="properties__image sp3"></div>
                  <div className="properties__image sp2"></div>
                  <div className="properties__image sp1"></div>
                </div>
                <div className="properties__blocks">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <Advantages />
          </div>
          <div className="section">
            <div className="section__page references">
              <Tabs>
                <TabPanel className="references__content">
                  <div className="references__image">
                    <img src={Turkcell} alt=""></img>
                  </div>
                  <div className="references__info">
                    <p>{i18next.t('app_site.references.text1')}</p>
                    <p>{i18next.t('app_site.references.title1')}</p>
                  </div>
                </TabPanel>
                <TabPanel className="references__content">
                  <div className="references__image">
                    <img src={Havaist} alt=""></img>
                  </div>
                  <div className="references__info">
                    <p>{i18next.t('app_site.references.text2')}</p>
                    <p>{i18next.t('app_site.references.title2')}</p>
                  </div>
                </TabPanel>

                <TabList className="references__tab">
                  <Tab className="references__tab-dot"></Tab>
                  <Tab className="references__tab-dot"></Tab>
                </TabList>
              </Tabs>
            </div>
          </div>
          <div className="section">
            <div className="section__page about-us">
              <div className="about-us__side about-us__left">
                <div className="about-us__icon">
                  LAGINA<br/>SIGNATURE
                </div>
              </div>
              <div className="about-us__side about-us__right">
                <div className="about-us__content">
                  <h3>{i18next.t('app_site.about_us.big_text')}</h3>
                  <p>{i18next.t('app_site.about_us.small_text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <Contact />
          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

 
export default SiteApp;