import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis } from 'recharts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import i18next from '../i18next';

 
class SignPage2a2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const WIDTH = this.state.width;
    const angle = [
      {name: '0s', pv: 1, amt: 2400}, 
      {name: '0.4s', pv: 0.3, amt: 2400}, 
      {name: '0.8s', pv: 0.7, amt: 2400}, 
      {name: '1.2s', pv: 0.5, amt: 2400},
      {name: '1.6s', pv: 0.4, amt: 2400},
      {name: '2s', pv: 0.6, amt: 2400}, 
      {name: '2.4s', pv: 0.2, amt: 2400},
      {name: '2.8s', pv: 0.3, amt: 2400},
    ];  

    const pressure = [
      {name: '0s', pv: 0.2, amt: 2400}, 
      {name: '0.4s', pv: 0.3, amt: 2400}, 
      {name: '0.8s', pv: 0.7, amt: 2400}, 
      {name: '1.2s', pv: 0.2, amt: 2400},
      {name: '1.6s', pv: 0.6, amt: 2400},
      {name: '2s', pv: 0.7, amt: 2400}, 
      {name: '2.4s', pv: 0.8, amt: 2400},
      {name: '2.8s', pv: 0.2, amt: 2400},
    ];

    const elevation = [
      {name: '0', pv: 0.45, amt: 2400}, 
      {name: '500', pv: 0.6, amt: 2400}, 
      {name: '1000', pv: 0.55, amt: 2400}, 
      {name: '1500', pv: 0.55, amt: 2400},
      {name: '2500', pv: 0.45, amt: 2400},
      {name: '3000', pv: 0.7, amt: 2400}, 
      {name: '3500', pv: 0.6, amt: 2400},
      {name: '4000', pv: 0.55, amt: 2400},
    ];

    return (
      
      <div className="about-sign__sign-page about-sp2a2">
        <div className="about-sign__side about-sp2a2__left">
          <div className="about-sign__inner-content about-sp2a2__inner-content">
            <h2>{i18next.t('app_page.page2a2.title')}</h2>
            <p>{i18next.t('app_page.page2a2.text')}</p>
            <Link className="about-sp2a2__nav about-sign__nav" to="/imza-2a3">{i18next.t('app_page.page2a2.button')}</Link>
          </div>
        </div>
        <div className="about-sign__side about-sp2a2__right">
          <div className="about-sp2a2__graph">
          <Tabs className="about-sp2a4__graph-tab">
            <TabList className="about-sp2a4__graph-nav">
              <Tab className="about-sp2a4__graph-nav-dot about-sp2a2__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value1')}</span>
              </Tab>
              <Tab className="about-sp2a4__graph-nav-dot about-sp2a2__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value2')}</span>
              </Tab>
              <Tab className="about-sp2a4__graph-nav-dot about-sp2a2__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value3')}</span>
              </Tab>
            </TabList>
            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={angle}
                width={WIDTH <= 768 ? (WIDTH * 0.8) : (WIDTH <= 1440 ? (WIDTH / 2) * 0.95 : (WIDTH / 2) * 0.8)}
                height={(WIDTH <= 480) ? 170 : ((WIDTH <= 768) ? 250 : 300)}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>

            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={pressure}
                width={WIDTH <= 768 ? (WIDTH * 0.8) : (WIDTH <= 1440 ? (WIDTH / 2) * 0.95 : (WIDTH / 2) * 0.8)}
                height={(WIDTH <= 480) ? 170 : ((WIDTH <= 768) ? 250 : 300)}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>

            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={elevation}
                width={WIDTH <= 768 ? (WIDTH * 0.8) : (WIDTH <= 1440 ? (WIDTH / 2) * 0.95 : (WIDTH / 2) * 0.8)}
                height={(WIDTH <= 480) ? 170 : ((WIDTH <= 768) ? 250 : 300)}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>
          </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2a2;