import React, { Component } from "react";
import i18next from '../i18next';

class Advantages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slider: [
        {
          title: i18next.t('app_site.advantages.title1'),
          text: i18next.t('app_site.advantages.text1'),
          symbol: "far fa-clock",
        },
        {
          title: i18next.t('app_site.advantages.title2'),
          text: i18next.t('app_site.advantages.text2'),
          symbol: "fal fa-copy",
        },
        {
          title: i18next.t('app_site.advantages.title3'),
          text: i18next.t('app_site.advantages.text3'),
          symbol: "fal fa-print",
        },
        {
          title: i18next.t('app_site.advantages.title4'),
          text: i18next.t('app_site.advantages.text4'),
          symbol: "fal fa-archive",
        },
        {
          title: i18next.t('app_site.advantages.title5'),
          text: i18next.t('app_site.advantages.text5'),
          symbol: "fas fa-unlock-alt",
        },
      ],
      i: 0,
      moving: false
    };
  }

  prevSlider = (step = 1) => {
    const { i, slider } = this.state;
    const { length } = slider;
    const next = ((i + (-step % length)) + length) % length
    
    this.setState({i: next})

    // let temp = 0;
    // temp = slider[0].symbol;
    // slider[0].symbol = slider[next].symbol;
    // slider[next].symbol = temp;

    this.setState({moving: true})

    setTimeout(function(){
      this.setState({slider, moving:false});
    }.bind(this),1000);
  }

  nextSlider = (step = -1) => {
    const { i, slider } = this.state;
    const { length } = slider;
    const next = ((i + (-step % length)) + length) % length
    
    this.setState({i: next})
    
    // let temp = 0;
    // temp = slider[0].symbol;
    // slider[0].symbol = slider[next].symbol;
    // slider[next].symbol = temp;

    this.setState({moving: true})
    
    setTimeout(function(){
      this.setState({slider, moving:false});
    }.bind(this),1000);
  }

  render() {
    const { slider, i, moving } = this.state;

    return (
      <div className="section__page advantages">
        <div className="advantages__disk-content">
          <div className={"advantages__disk-item advantages__disk-main" + (moving ? " active": "")}>
            <i className={slider[i].symbol}></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className="fas fa-chess-rook"></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className={slider[3].symbol}></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className="fas fa-list-ul"></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className={slider[1].symbol}></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className={slider[4].symbol}></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className={slider[2].symbol}></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className="fal fa-truck"></i>
          </div>
          <div className={"advantages__disk-item advantages__disk-other" + (moving ? " active": "")}>
            <i className="fas fa-gem"></i>
          </div>
        </div>
        <div className="advantages__bottom-content">
          <div className="advantages__info">
            <h3>{slider[i].title}</h3>
            <p>{slider[i].text}</p>
          </div>
          <div className="advantages__navigation">
            <div className="advantages__nav-left" onClick={() => this.prevSlider()}>
              <i className="far fa-chevron-left"></i>
            </div>
            <div className="advantages__nav-right" onClick={() => this.nextSlider()}>
              <i className="far fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Advantages;