import i18next from 'i18next';

i18next
  .init({
    lng: 'en',
    fallbackLng: ['en', 'tr'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
        translation: {
          app_site: {
            anchors: "",
            home: "ıslak imzayı dijitale dönüştüren teknoloji",
            about_home: {
              big_title: "tanımaya hazırsan başlayalım!",
              small_title: "Lagina Signature bir biyometrik imza uygulamasıdır.",
              button: "Denemeye Başla",
              demo_title:"Demo Talep Edin"
            },
            properties: {
              title: "SİSTEM ÖZELLİKLERİ",
              text1: "AB’ince kabul edilen (ISO-19794:2007 ve ISO-19794:2014) kimlik doğrulama standartlarına uygundur.",
              text2: "Elle atılan imzadaki basıncı, hızı, yönü, elin salınımını ve ivmeyi yakalar.",
              text3: "Adli tıp uzmanlarının kullandığı “Ex-post” imza doğrulama özelliğine sahiptir.",
              text4: "Asimetrik şifreleme ile imza geri döndürülemez ve kopyalanamaz.",
              text5: "Atılan imzanın zaman ve konum bilgisi güvenli bir şekilde doküman üzerinde saklanır.",
              text6: "Delil özelliğinin kuvvetlendirilmesi için kimlik resmi, zaman damgası, ses kaydı, GPS bilgileri ve sms doğrulama gibi özelliklerle destekler ve eş zamanlı olarak kayıt altına alır.",
              text7: "Servis Sağlayıcı bağımsız belge bütünlüğü doğrulama standardı olan ISO-32000’i destekler.",
              text8: "Belge üzerindeki tüm değişiklikleri yakalar.",
              text9: "Klasik kağıt döküman imzalama deneyimini sunar.",
              text10: "Tüm veritabanı sistemlerini destekler.",
              text11: "Elektronik dokümanlara hukuki geçerlilik sağlar."
            },
            advantages: {
              title1: "ZAMAN KAZANIN",
              title2: "KAĞIT TASARRUFU",
              title3: "KARTUŞ MALİYETİNİN SONU",
              title4: "ALAN TASARRUFU",
              title5: "%100 GÜVENLİK",
              text1: "İşlemleri saniyeler içinde yaparak zamandan ve iş gücünden tasarruf etmenizi sağlar.",
              text2: "Kağıdı süreçten tamamen kaldırarak matbaa maliyetleri sıfıra indirir.",
              text3: "Tüm süreci dijital alana taşıyarak yazdırma ve tarama gibi maliyetleri ortadan kaldırır.",
              text4: "Dijital arşivleme sayesinde taşıma ve depolama maliyetlerini sıfıra indirir.",
              text5: "Asimetrik şifreleme yöntemi ile saklanan belgelerde, herhangi bir sahtecilik yapılamaz."
            },
            references: {
              text1: "2017'de başlattığımız 'Güvenli Dijital İmza' uygulamasıyla evrak işlerini dijitalleştirdik ve daha güvenli hale getirdik. İşlemlerin hızlı ve kolay bir şekilde tamamlanmasını sağlayan bu uygulama aynı zamanda çevreye ve ekonomiye de katkı sunmakta. Dijital imza ile kağıt, toner ve kurye masraflarından 14 milyon TL tasarruf ettik. Satış sonrası kontrol, saklama gibi hizmetleri de eklediğimizde ekonomimize 35 milyon TLlik bir fayda oluşturduk.",
              title1: "Murat Erkan - CEO, Turkcell",
              text2: "Lagina'nın imza altyapısını kullanmak farklı açılardan Havaist'e avantaj sağladı. Bu avantajların başında; maliyetlerin azalması ve güvenlik gibi konular geliyor. Lagina ile dijitalleşen imza süreci, evrak işlerinin daha kısa zamanda ve daha az maliyetle sonlanmasına yardımcı oluyor. İmza doğrulama özelliği ise Havaist gibi güvenliğe büyük önem veren bir şirketin tüm ihtiyaçlarını karşılayacak nitelikte.",
              title2: "Volkan Başkaya-Havaist Genel Koordinatörü"
            },
            about_us: {
              big_text: "Tarihte ilk imza bu topraklarda, Lagina medeniyetinde atıldı. Lagina’dan aldığımız ilhamla binlerce yıl sonra imzayı dönüştürüyoruz.",
              small_text: "Dünyada gelişmekte olan dijital dönüşüm trendi ülkemizde de aynı hızla yayılıyor. Bu durum şirketlerin iş süreçlerini daha hızlı ve güvenli yönetmesini sağlarken verimliliği de artırıyor. Alanında uzman çalışanlarımız ile ürettiğimiz yerli yazılımlarla biz de bu gelişime katkı sağlıyoruz. Biyometrik imza da tıpkı geliştirdiğimiz diğer ürünler gibi şirketler için daha verimli çalışma imkanı sağlarken, insanlar için de daha güvenli ve yaşanabilir bir dünyayı amaçlıyor. Ülkemizin büyük markalarına hizmet vermenin sorumluluğu ve teknolojinin insanlığa olan katkısının bilinci ile hep daha iyisini arıyoruz."
            },
            contact: {
              title: "BİZE ULAŞIN",
              label: {
                name: "İSİM",
                e_mail: "E-MAIL",
                phone: "TEL",
                message: "MESAJINIZ"
              },
              button: "GÖNDER",
              success: "Mesajınız gönderilmiştir.",
              fail: "Bir hata oluştu."
            }
          },
          app_page: {
            link: "Geri",
            documents: {
              type1: "Sözleşme",
              type2: "Teslimat Formu",
              type3: "Kontrat",
              type4: "Makbuz",
              type5: "İzin Belgesi"
            },
            graph: {
              value1: "Tutuş Açısı",
              value2: "Basınç",
              value3: "Yükselti",
              value4: "Lokasyon"
            },
            page1: {
              title: "Biyometrik imzayı denemek için bir döküman seçin...",
              text: "Biyometrik imza bir çok sektörde kullanılabilir. Güvenli dijital imzayı kullanmak istediğiniz örnek belge türünü seçin."
            },
            page2: {
              title: "İmza yöntemini seçin",
              text: "Bulunduğunuz sektörde kullandığınız belgeler için 2 farklı imza yöntemi vardır. Aşağıdaki yöntemlerden hangisini denemek istersiniz?",
              button1: "Geleneksel İmza",
              button2: "Biyometrik İmza",
              sign_text: "Teslim Alan İmza"
            },
            page2b: {
              small_text: "İmzada geleneksel yöntemi tercih ettiniz ve evraklarınız için fiziksel arşiv ihtiyacınız olduğunu anladık. Geleneksel imza yönetimini tercih ediyorsanız Arşimet Dijital Arşiv hizmetimiz tam size göre.",
              big_text: " Evrak başına 3 TL maliyetten kurtulmak ve süreci daha hızlı yönetmek istiyorsanız Lagina Biyometrik İmza’ya geri dönebilirsiniz.",
              button1: "Arşimet'e Git",
              button2: "Geri Dön"
            },
            page2a: {
              title: "Deneyin!",
              big_text: "Biyometrik imza sürecinde basınç algılayan bir tablet ve ona uygun bir kalem kullanılır.",
              small_text: "Attığınız imza sonraki aşamada, basınç, hız, koordinat vs. bilgilerinin değerlendirilebilmesi için temsili olarak kullanılmaktadır.",
              button1: "Vazgeç",
              button2: "Devam Et"
            },
            page2a1: {
              title: "Tebrikler!",
              text: "İşte bir belgede biyometrik imza kullanarak kazandıklarınız…",
              icon_text1: "65 DAKİKA",
              icon_text2: "37 AĞAÇ",
              icon_text3: "5 LİTRE",
              button1: "Tüm Avantajlar",
              button2: "İmza Analizini Gör"
            },
            page2a2: {
              title: "%100 güvenli dökümanlar için",
              text: "Lagina Signature teknolojisinin imza doğrulama özelliği, sahte imza kullanımının önüne geçer.",
              button: "Nasıl Çalışır ?"
            },
            page2a3: {
              text: "Yukarıdaki imzanın aynısını çizmesini deneyin.",
              button: "Analizi Gör"
            },
            page2a4: {
              title: "Benzerlik",
              text: "İmzanız; basınç, ivme, hız, yön ve elin salınımı gibi bilgiler incelenerek analiz edildi. Önceki verilerle karşılaştırıldı ve %100 güvenlik sağlandı.",
              button1: "Uygulamayı Satın Al",
              button2: "Daha Fazla Bilgi"
            }
          }
        },
      },
      en: {
        translation: {
          app_site: {
            anchors: "",
            home: "technology transforms signature into digital",
            about_home: {
              big_title: "Let’s start if you're ready to recognize!",
              small_title: "Lagina Signature is a biometric signature application.",
              button: "Try Now",
              demo_title:"Request a Demo"
            },
            properties: {
              title: "SYSTEM FEATURES",
              text1: "Complies with EU-accepted ID verification authentication standards (ISO-19794: 2007 and ISO-19794: 2014)",
              text2: "Our technology captures the hand pressure, changes of direction, speed, azimuth and acceleration.",
              text3: "It has Ex-post signature verification feature used by forensic experts.",
              text4: "With asymmetric encryption, the signature cannot be restored and copied.",
              text5: "The time and location of the signature are securely stored on the document.",
              text6: "In order to support the signature evidence, the signed document supported by time stamp, ID photo, voice record, GPS information, sms verification features and all features are recorded simultaneously.",
              text7: "It supports, the independent document integrity verification Service Provider of ISO-32000.",
              text8: "Captures all modifications into the document.",
              text9: "Provides the classic paper document signing experience",
              text10: "Supports all database systems",
              text11: "Provides legal validity to electronic documents"
            },
            advantages: {
              title1: "SAVE YOUR TIME",
              title2: "PAPER SAVING",
              title3: "END CARTRIDGE COST",
              title4: "SPACE SAVING",
              title5: "%100 SECURED",
              text1: "All transactions are performed in seconds and saves your time and labour force.",
              text2: "Removes paper from the signing process and cuts off printing costs.",
              text3: "Transfers the entire process to digital, removes printing and scanning costs. ",
              text4: "Reduces transport and storage costs to the zero through digital archiving.",
              text5: "Prevents signature forgery on documents which stored with asymmetric encryption method."
            },
            references: {
              text1: "The ‘Secure Digital Signature’ application that we begin in 2017, we digitized and made documents more secure. The signature application which also enables the completion of transactions quickly and easily, contributes to both the environment and the economy. We saved 14 Million Turkish Lira from paper, toner and courier costs with Digital Signature. Along with the extra services such as after-sales service & storage, we have created 35 Million Turkish Lira benefit to our economy.",
              title1: "Murat Erkan - CEO, Turkcell",
              text2: "Using the Lagina’s biometric signature infrastructure has provided advantages to Havaist from different aspects. The very first of the  advantages are that reduction of the costs and solving the security issues. The signature process, digitized with Lagina, helps to finish paperwork in less time with less cost. The signature verification feature is able to meet all the needs of a company like Havaist that attaches great importance to security.",
              title2: "Volkan Başkaya- Coordinator General",
            },
            about_us: {
              big_text: "The first signature in the history written by Lagina Civilization. The inspiration we got from Lagina made us to transform the signature into digital.",
              small_text: "The global developing digital transformation trend has been growing at the same speed in our country. This enables corporates manage their business process much faster and more secure while increasing productivity. We are honored by contributing to this process with our local software that we produce with our expert staff. Biometric Signature Solution, just like in our other solutions, we are pursuing to create more efficient working environment to corporates while aiming to more secure and livable environment for people. We are always looking for the better with the responsibility of serving the big brands of our country and the awareness of the contribution of technology for the humanity."
            },
            contact: {
              title: "CONTACT US",
              label: {
                name: "NAME",
                e_mail: "E-MAIL",
                phone: "PHONE",
                message: "YOUR MESSAGE"
              },
              button: "SEND",
              success: "Your message has been sent",
              fail: "Something went wrong"
            }
          },
          app_page: {
            link: "Back",
            documents: {
              type1: "Agreement",
              type2: "Deliveriy Form",
              type3: "Contract",
              type4: "Receipt",
              type5: "Warrant"
            },
            graph: {
              value1: "Grip Angle",
              value2: "Pressure",
              value3: "Elevation",
              value4: "Location"
            },
            page1: {
              title: "Select a document to try Biometric Signature...",
              text: "Biometric Signature can be used in many sectors. Select the sample document type to use Digital Signature"
            },
            page2: {
              title: "Select signing method",
              text: "There are 2 different methods for signing documents that you used in your industry. Which of the following methods you would like to try?",
              button1: "Traditional Sign",
              button2: "Biometric Sign",
              sign_text: "Recipient Sign"
            },
            page2b: {
              small_text: "You prefer the traditional signature method and we understood that you need physical archiving for your documents. If you prefer the traditional signature method then our Arsimet Digital Archive service is perfect for you.",
              big_text: "If you avoid to pay the per document cost of 1 Dolar and manage the process faster, you can go back to Lagina Biometric Signature",
              button1: "Go to Arsimet",
              button2: "Go Back"
            },
            page2a: {
              title: "Try Now!",
              big_text: "There is only a tablet that detects pressure and a compatible pen are used in biometric signature process.",
              small_text: "Your signature is used as a representational for the evaluation of the information such as pressure, speed, coordinate etc. on the next stage.",
              button1: "Cancel",
              button2: "Continue"
            },
            page2a1: {
              title: "Congratulations!",
              text: "Here is what you get by using Biometric Signature on one document…",
              icon_text1: "65 MINUTES",
              icon_text2: "37 TREES",
              icon_text3: "5 LITRES",
              button1: "Advantages",
              button2: "View Sign Analysis"
            },
            page2a2: {
              title: "For %100 secure documents",
              text: "Lagina Signature Technology’s signature verification feature prevents counterfeit signatures.",
              button: "How does it work ?"
            },
            page2a3: {
              text: "Try to draw the same signature above.",
              button: "View Analysis"
            },
            page2a4: {
              title: "Similarity",
              text: "Your signature has been analyzed by examining pressure, acceleration, speed, direction, and hand release data. It is compared with previous data and %100 security was granted.",
              button1: "Purchase the App",
              button2: "More Information"
            }
          }
        },
      },
    },
  })

export default i18next
