import React, { Component } from "react";
//import axios from 'axios';
import i18next from '../i18next';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis } from 'recharts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Location from '../components/Location';
 
class SignPage2a extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      prediction: this.props.location.state.prediction
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    // var qs = require('qs');

    // const { cvs_dataURL, img_dataURL } = this.state;
    // const data = { reference: img_dataURL, target: cvs_dataURL  }
    // const headers = {
    //   "content-type": "application/x-www-form-urlencoded"
    // }
    // axios.post(`http://127.0.0.1:5001/tensorflow`, qs.stringify(data), { headers })
    //   .then(res => {
    //     console.log(res);
    //     console.log(res.data);
    //     const num = (100 - (1 - (res.data) / 1.8 ) * 100);
    //     this.setState({
    //       prediction: num.toPrecision(2)
    //     })
    //   })

    console.log(this.state.prediction)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { prediction } = this.state;
    const WIDTH = this.state.width;
    const angle = [
      {name: '0s', pv: 1, amt: 2400}, 
      {name: '0.4s', pv: 0.3, amt: 2400}, 
      {name: '0.8s', pv: 0.7, amt: 2400}, 
      {name: '1.2s', pv: 0.5, amt: 2400},
      {name: '1.6s', pv: 0.4, amt: 2400},
      {name: '2s', pv: 0.6, amt: 2400}, 
      {name: '2.4s', pv: 0.2, amt: 2400},
      {name: '2.8s', pv: 0.3, amt: 2400},
    ];  

    const pressure = [
      {name: '0s', pv: 0.2, amt: 2400}, 
      {name: '0.4s', pv: 0.3, amt: 2400}, 
      {name: '0.8s', pv: 0.7, amt: 2400}, 
      {name: '1.2s', pv: 0.2, amt: 2400},
      {name: '1.6s', pv: 0.6, amt: 2400},
      {name: '2s', pv: 0.7, amt: 2400}, 
      {name: '2.4s', pv: 0.8, amt: 2400},
      {name: '2.8s', pv: 0.2, amt: 2400},
    ];

    const elevation = [
      {name: '0', pv: 0.45, amt: 2400}, 
      {name: '500', pv: 0.6, amt: 2400}, 
      {name: '1000', pv: 0.55, amt: 2400}, 
      {name: '1500', pv: 0.55, amt: 2400},
      {name: '2500', pv: 0.45, amt: 2400},
      {name: '3000', pv: 0.7, amt: 2400}, 
      {name: '3500', pv: 0.6, amt: 2400},
      {name: '4000', pv: 0.55, amt: 2400},
    ];

    return (
      <div className="about-sign__sign-page about-sp2a4">
        <div className="about-sign__inner-content about-sp2a4__inner-content">
          <Tabs className="about-sp2a4__graph-tab">
            <TabList className="about-sp2a4__graph-nav">
              <Tab className="about-sp2a4__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value1')}</span>
              </Tab>
              <Tab className="about-sp2a4__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value2')}</span>
              </Tab>
              <Tab className="about-sp2a4__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value3')}</span>
              </Tab>
              <Tab className="about-sp2a4__graph-nav-dot">
                <span>{i18next.t('app_page.graph.value4')}</span>
              </Tab>
            </TabList>
            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={angle}
                width={(WIDTH <= 768) ? (WIDTH * 0.85) : (WIDTH <= 1366 ? 660 : 720)}
                height={WIDTH <= 480 ? 150 : ((WIDTH <= 1366) ? 180 : (WIDTH <= 1440 ? 220 : 270))}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>

            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={pressure}
                width={(WIDTH <= 768) ? (WIDTH * 0.85) : (WIDTH <= 1366 ? 660 : 720)}
                height={WIDTH <= 480 ? 150 : ((WIDTH <= 1366) ? 180 : (WIDTH <= 1440 ? 220 : 270))}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>

            <TabPanel className="about-sp2a4__graph-content">
              <AreaChart 
                data={elevation}
                width={(WIDTH <= 768) ? (WIDTH * 0.85) : (WIDTH <= 1366 ? 660 : 720)}
                height={WIDTH <= 480 ? 150 : ((WIDTH <= 1366) ? 180 : (WIDTH <= 1440 ? 220 : 270))}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>             
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Area type="monotone" dataKey="pv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </TabPanel>
            <TabPanel className="about-sp2a4__graph-content">
              <Location />
            </TabPanel>
          </Tabs>
          <h2>{i18next.t('app_page.page2a4.title')} %{prediction} :)</h2>
          <p>{i18next.t('app_page.page2a4.text')}</p>
          <div className="about-sp2a4__choices  about-sign__choices">
          <a href="/#İLETİŞİM" className="about-sign__nav--other">{i18next.t('app_page.page2a4.button1')}</a>
          <a href="/#ÖZELLİKLER"className="about-sign__nav">{i18next.t('app_page.page2a4.button2')}</a>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2a;