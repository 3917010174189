import React, { Component } from "react";
import { Link } from 'react-router-dom';
import i18next from '../i18next';
import SignUp from '../components/SignUp'
import Signed from '../assets/images/Signed2.png'

class SignPage2a3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prediction: 0,
      cvs_dataURL: "",
    };
  }

  setDataUrl = (dataURL) => {
    let cvs_dataURL = dataURL.split(',')[1];
    this.setState({
      cvs_dataURL
    })
  }

  componentDidMount() {
    this.getRandomPrediction()
  }

  getRandomPrediction() {
    const min = 1;
    const max = 30;
    const rand = (min + Math.random() * (max - min)).toPrecision(4);

    this.setState({ prediction: rand });
  }

  render() {
    console.log(this.state.cvs_dataURL)
    return (
      <div className="about-sign__sign-page about-sp2a3">
        <div className="about-sign__inner-content about-sp2a3__inner-content">
          <div className="about-sp2a3__signed">
            <img src={Signed} alt="" />
          </div>
          <p>{i18next.t('app_page.page2a3.text')}</p>
          <SignUp getDataUrl={this.setDataUrl} />
          {
            this.state.cvs_dataURL === "" ?
            <a href className="about-sign__nav about-sign__nav--disabled" >{i18next.t('app_page.page2a3.button')}</a> :
            <Link to={{pathname: "/imza-2a4", state: { prediction: this.state.prediction }}} className="about-sign__nav" >{i18next.t('app_page.page2a3.button')}</Link>
          }
        </div>
      </div>
    );
  }
}

export default SignPage2a3;