import React, { Component } from "react";
import { Link } from 'react-router-dom';
import i18next from '../i18next';

 
class SignPage2b extends Component {

  constructor(props) {
    super(props);
    this.state = {
      document: "undefined" ? i18next.t('app_page.documents.type1') : this.props.location.state.document  //contains the document type(name/title) passes through router link
    };
  }

  render() {
    return (
      <div className="about-sign__sign-page about-sp2b">
        <div className="about-sp2b__cover">
          <div className="about-sign__inner-content about-sp2b__inner-content">
            <h2>Ops!</h2>
            <p>{i18next.t('app_page.page2b.small_text')}</p>
            <h3>{i18next.t('app_page.page2b.big_text')}</h3>
            <div className="about-sp2b__choices  about-sign__choices">
              <a href="http://dijitalarsiv.net/" target="_blank" rel="noopener noreferrer" className="about-sign__nav--other">{i18next.t('app_page.page2b.button1')}</a>
              <Link to={{pathname: "/imza-2", state: { document: this.state.document }}} className="about-sign__nav">{i18next.t('app_page.page2b.button2')}</Link>
            </div>
            <div className="about-sp2b__pages">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default SignPage2b;