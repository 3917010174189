import React, { Component } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

export class Location extends Component {
  state = { userLocation: { lat: 32, lng: 32 }, loading: true, activeMarker: {} };
  

  componentDidMount(props) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;

        this.setState({
          userLocation: { lat: latitude, lng: longitude },
          loading: false
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { loading, userLocation } = this.state;
    const { google } = this.props;

    const style = {
      width: '100%',
      height: '100%'
    }
  
    if (loading) {
      return null;
    }

    return <Map style={style} google={google} initialCenter={userLocation} zoom={10}>
      <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
    </Map>;
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDjX3lfrHMTpGqKW9oFJ76c3cx2vN5zYcQ"
})(Location);